<template>
  <div
    class="grid grid-cols-1 md:grid-cols-3 gap-base-1/2 text-xl text-center leading-tight"
  >
    <div class="grid md:block md:grid-cols-[100px_1fr] items-center gap-x-4">
      <div class="text-[2em] font-bold flex-none">25.000+</div>
      bezoekers per maand
    </div>
    <div class="grid md:block md:grid-cols-[100px_1fr] items-center gap-x-4">
      <div class="text-[2em] font-bold flex-none">2.400+</div>
      aangesloten bedrijven
    </div>
    <div class="grid md:block md:grid-cols-[100px_1fr] items-center gap-x-4">
      <div class="text-[2em] font-bold flex-none">1.500+</div>
      vacatures per jaar
    </div>
  </div>
</template>

<script lang="ts" setup></script>
